<!--
  チェックボックスの単体 checkbox-listで使用
-->
<template>
  <div
    class="input-check-inner"
    :class="{
      'input-check-small': isSmall
    }"
  >
    <label
      :class="{
        'input-check-disabled': isDisabled
      }"
    >
      <input
        class="input-check-box"
        type="checkbox"
        :value="text"
        :checked="isChecked"
        :disabled="isDisabled"
        @change="updateValue($event)"
      >
      <div
        class="input-check-icon"
        :class="{
          'input-check-icon-disabled': isDisabled,
          'input-check-icon-caution': caution
        }"
      >
        <icons
          class="input-check-icon-check"
          :iconName="isShowMinusIcon ? 'minus' : 'check'"
          :size="isSmall ? 12 : 'min'"
          :color="isDisabled ? 'gray' : caution ? 'caution' : 'emphasis'"
        />
      </div>
      <div
        class="input-check-text"
        :class="{
          'input-check-text-large': isLarge
        }"
      >
        <texts
          :text="text"
          :color="caution ? 'caution' : 'default'"
          :size="isSmall ? 'min' : isLarge ? 'default' : 'small'"
        />
      </div>
    </label>
  </div>
</template>

<script>
import texts from './text'
import icons from './icon'
export default {
  components: {
    texts,
    icons
  },
  data() {
    return {
      isChecked: this.checked
    }
  },
  props: {
    /** チェックボックスに表示するテキストとvalueに代入する値 */
    text: String,
    /** チェックが入っているか */
    checked: Boolean,
    /** 使用不可状態 */
    isDisabled: Boolean,
    /** マイナスアイコンを表示 */
    isShowMinusIcon: Boolean,
    /** 注意を表示するかどうか */
    caution: {
      type: Boolean,
      default: false
    },
    /** 大きく表示するかどうか */
    isLarge: Boolean,
    /** 小さく表示するかどうか */
    isSmall: Boolean
  },
  methods: {
    updateValue(e) {
      this.isChecked = e.target.checked
      this.$emit('input', this.isChecked)
      this.$emit('change', this.isChecked)
    }
  },
  watch: {
    checked(newVal) {
      this.isChecked = newVal
    }
  }
}
</script>

<style lang="scss" scoped>
.input-check {
  &-inner {
    position: relative;
    padding: 0 $space-medium 0 $space-large;
    margin: $space-small 0;
    cursor: pointer;
  }
  &-icon {
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: adjustVW(24);
    height: adjustVW(24);
    border: $border-title-gray;
    background: $background;
    border-radius: adjustVW(4);
    transform: translateY(-50%);
    &-check {
      opacity: 0;
    }
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: adjustVW(40);
      height: adjustVW(40);
      transform: translate(-50%, -50%);
    }
    &-caution {
      border: $border-caution;
    }
    &-disabled {
      border: $border-title-gray;
      background: $medium-gray;
      opacity: 0.5;
    }
  }
  &-text-large {
    padding-left: $space-text;
  }
  &-disabled {
    cursor: not-allowed;
  }
  &-small {
    padding: 0 0 0 adjustVW(20 + 8);
    margin: 0;
    .input-check {
      &-icon {
        width: adjustVW(20);
        height: adjustVW(20);
        &::after {
          width: adjustVW(32);
          height: adjustVW(32);
        }
      }
    }
  }
}
.input-check-box[type='checkbox']:checked + .input-check-icon {
  border: $border-emphasis;
  background: $background;
  > .input-check-icon-check {
    opacity: 1;
  }
  &.input-check-icon-disabled {
    border: $border-transparent;
    background: $medium-gray;
    opacity: 1;
  }
  &.input-check-icon-caution {
    border: $border-caution;
  }
}
</style>
